import {
  LinkPreviewProviders,
  WixRicosViewer,
  pluginCodeBlockViewer as pluginCodeBlock,
  pluginEmojiViewer as pluginEmoji,
  pluginHashtagViewer as pluginHashtag,
  pluginIndentViewer as pluginIndent,
  pluginLineSpacingViewer as pluginLineSpacing,
  pluginSpoilerViewer as pluginSpoiler,
} from '@wix/ricos'
import {
  pluginAudioLoadable as pluginAudio,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginDividerLoadable as pluginDivider,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginGalleryLoadable as pluginGallery,
  pluginGiphyLoadable as pluginGiphy,
  pluginHtmlLoadable as pluginHtml,
  pluginImageLoadable as pluginImage,
  pluginLinkLoadable as pluginLink,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginVideoLoadable as pluginVideo,
} from '@wix/ricos/loadable'
import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import React, {ComponentProps} from 'react'
import {usePageStateSelector} from '../../../hooks/state'
import {getEvent} from '../../../selectors/event'
import {RicosViewerProps} from './interfaces'
import '@wix/ricos/css/ricos-viewer.global.css'
import '@wix/ricos/css/plugin-hashtag-viewer.global.css'
import '@wix/ricos/css/plugin-spoiler-viewer.global.css'

export const RicosViewer = ({content}: RicosViewerProps) => {
  const {isMobile} = useEnvironment()
  const eventId = usePageStateSelector(state => getEvent(state).id)
  const instance = usePageStateSelector(state => state.instance.instance)
  const msid = usePageStateSelector(state => state.instance.msid)

  return (
    <WixRicosViewer
      content={content}
      instance={instance}
      msid={msid}
      plugins={plugins}
      theme={theme}
      isMobile={isMobile}
      biSettings={{consumer: 'Events', postId: eventId, containerId: EVENTS_APP_ID, platform: 'Livesite'}}
    />
  )
}

const plugins = [
  pluginAudio(),
  pluginCodeBlock(),
  pluginCollapsibleList(),
  pluginDivider(),
  pluginEmoji(),
  pluginFileUpload(),
  pluginGallery(),
  pluginGiphy(),
  pluginHashtag(),
  pluginHtml(),
  pluginImage(),
  pluginIndent(),
  pluginLineSpacing(),
  pluginLink(),
  pluginLinkPreview({
    exposeEmbedButtons: [LinkPreviewProviders.Instagram, LinkPreviewProviders.Twitter, LinkPreviewProviders.TikTok],
  }),
  pluginSpoiler(),
  pluginVideo(),
]

const theme: ComponentProps<typeof WixRicosViewer>['theme'] = {
  useWixStyles: true,
  typography: {
    fontFamily: 'var(--textFontV2-family)',
  },
  palette: {
    type: 'rgb',
    actionColor: 'var(--linksColor)',
    textColor: 'var(--textColorV2)',
  },
}
